import React from "react"

import LabelImportant from "@mui/icons-material/LabelImportant"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CustomList from "../components/CustomList"
import SectionTitle from "../components/SectionTitle"
import content from "../helpers/content.json"
import { capitalFirstLetter } from "../helpers/common"
import { pageSetting } from "../helpers/theme"

const ServicesCrewPay = () => {
  const title = content.ofert.content["services_crew"].title
  const data = content.ofert.content["services_crew"].blocks[0].subContent
  const arrM = data.split(".").map(str => str.trim())
  const arr = arrM.map(el => capitalFirstLetter(el))

  return (
    <Layout>
      <Seo title={capitalFirstLetter(title)} />
      <SectionTitle title={title} />
      <CustomList
        items={arr}
        icon={<LabelImportant css={{ color: pageSetting.attention }} />}
      />
    </Layout>
  )
}

export default ServicesCrewPay
